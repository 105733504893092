import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TopUpModal } from "../../../components/Modal/TopUpModal";
import FooterAddRecipients from "../../../components/campaign/FooterAddRecipients";
import RecipientsProgress from "../../../components/campaign/progress/RecipientsProgress";
import BackButton from "../../../components/common/BackButton";
import Header from "../../../components/dashboard/Header";
import Api from "../../../constants/api";
import { setCampaignResponseData } from "../../../redux/campaignSlice";
import { updateBalance } from "../../../redux/corpSlice";
import { setIsLoading } from "../../../redux/globalSlice";
import { useAppSelector } from "../../../redux/hooks";
import { getTriggerTimestamp } from "../../../utils/campaign.utils";
import { SubscriptionType } from "../../../utils/constant";
import CustomNavigate from "../../../utils/navigate";
import { TCampaignValue } from "../CampaignCreate";

const AddRecipients = () => {
  const navigate = new CustomNavigate(useNavigate());
  const topDivRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const giftingUrl = process.env.REACT_APP_GIFTING_URL;

  const globalState = useAppSelector((state) => state.globalSliceState);
  const corpId = globalState.authData?.corp?.id!;

  const campaign = useLocation().state as CampaignListData;

  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [campaignValue, setCampaignValue] = useState<TCampaignValue>({
    campaignName: "",
    selectedAmount: 0,
    otherAmount: "",
    campaignType: "",
    collectionList: [],
    charityList: [],
    occasionList: [],
    productIds:[],
    charityToggle: false,
    campaignTriggerTime: "on occasion day",
    campaignStartDate: "",
    campaignEndDate: "",
    campaignEndDateNotRequired: false,
    campaignStartTime: "",
    selectedOccasion: -1,
    selectedTheme: -1,
    selectedThemeName:"",
    recipientMessageTitle: "Dear",
    messageMethod: "automate",
    recipientMessage: "",
    recipientMessageFrom: "",
    recipientMessageFooter: "",
    selectedGreetingCard: "SelectGroup",
    recipientAddressingName: "",
    isSelfPickupOnly: false,
    pickupAddress: "",
    showVouchers: false,
    recipientCustomFieldTriggerTime: SubscriptionType.OneTime,
    recipientCustomField: "",
    isRecurring: false,
    recipientStartDate: "",
    recipientStartTime: "",
    isTouchpointEnabled: false,
    isReferralEnabled: false,
  });

  const [recipientList, setRecipientList] = useState<OrderRecipientPartial[]>(
    []
  );
  const [selectedRecipients, setSelectedRecipients] = useState<
    OrderRecipientPartial[]
  >([]);
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [selectedRecipientGroup, setSelectedRecipientGroup] = useState(-1);
  const [recipientGroups, setRecipientGroups] = useState<
    GetAllRecipientGroupData[]
  >([]);
  const [numberOfLinks, setnumberOfLinks] = useState<string>();
  const selectAllRecipients = useCallback(() => {}, []);
  const paginations = Math.ceil(recipientList.length / 10);

  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;

  let paginationList = <></>;
  if (paginations > 1) {
    paginationList = (
      <div className="flex gap-2 w-full overflow-scroll ">
        {[...Array(paginations)].map((v, i) => {
          return (
            <div
              key={i}
              className={`p-1 px-2 border rounded-lg cursor-pointer ${
                paginationIndex === i && "bg-orange text-white"
              }`}
              onClick={() => setPaginationIndex(i)}
            >
              {i + 1}
            </div>
          );
        })}
      </div>
    );
  }

  const updateValues = (
    value: string | boolean | Date,
    key: keyof TCampaignValue
  ) => {
    setCampaignValue({ ...campaignValue, [key]: value });
  };

  useEffect(() => {
    axios
      .get(`${giftingUrl}recipient/group/${selectedRecipientGroup}`, {
        params: {
          pageNumber,
          pageSize,
          corpId,
        },
      })
      .then((res) => {
        setTotalPages(res.data.data.totalPages);
        setRecipientList(res.data.data.results ?? []); // ?? []
      });
    axios.get(`${giftingUrl}recipient/allGroups/${corpId}`).then((res) => {
      setRecipientGroups([{ id: -1, name: "All Groups" }, ...res.data.data]);
    });
  }, [corpId, giftingUrl, pageNumber, selectedRecipientGroup]);

  const validateRecipients = (recipientsList: CreateCampaignRecipients[]) => {
    recipientsList.forEach((recipient) => {
      if (!recipient.firstName) {
        toast.error("First name is required");
        return false;
      }
    });
    return true;
  };

  const onConfirm = useCallback(() => {
    let recipients: CreateCampaignRecipients[] = [];
    switch (campaignValue.selectedGreetingCard) {
      case "GenerateLinks":
        recipients = [...Array(numberOfLinks ? +numberOfLinks : 0)].map(() => {
          return {
            name: campaignValue?.recipientAddressingName,
            email: "NA",
            phoneNumber: "NA",
            firstName: "",
            lastName: "",
            birthDate: moment().format("YYYY-MM-DD"),
            role: "NA",
            department: "NA",
          };
        });
        break;
      case "SelectGroup":
        recipients = selectedRecipients.map((recipient) => ({
          id: recipient.id,
          name: recipient.firstName,
          firstName: recipient.firstName,
          email: recipient.email,
          lastName: recipient.lastName,
          phoneNumber: recipient.phoneNumber,
          birthDate: recipient.birthDate,
          role: recipient.role,
          department: recipient.department,
          createdAt: recipient.createdAt,
        }));
        break;
      default:
        recipients = selectedRecipients.map((recipient) => ({
          name: recipient.firstName,
          firstName: recipient.firstName,
          email: recipient.email,
          lastName: recipient.lastName,
          phoneNumber: recipient.phoneNumber,
          birthDate: recipient.birthDate,
          role: recipient.role,
          department: recipient.department,
          createdAt: recipient.createdAt,
        }));
        break;
    }

    recipients = recipients.map((r) => {
      r.birthDate = moment(r.birthDate).format("YYYY-MM-DD");
      r.triggerTimeStamp = getTriggerTimestamp(
        r.birthDate,
        campaignValue?.campaignTriggerTime,
        campaignValue?.campaignStartTime
      );

      return r;
    });

    const data: AddRecipients = {
      recipients,
      saveRecipients: campaignValue?.selectedGreetingCard !== "GenerateLinks",
    };

    if (!validateRecipients(recipients)) {
      toast.error("Invalid Recipients");
      return;
    }

    dispatch(setIsLoading(true));
    Api.addRecipients(campaign.id, data)
      .then((res) => {
        setIsLoading(false);
        dispatch(setCampaignResponseData(res.data));
        toast.success("Recipients added successfully.");
        navigate.to("/dashboard/campaigns");
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("There was an error adding recipients.");
      })
      .then(() => Api.getOneCorp(corpId!))
      .then((res) => {
        dispatch(updateBalance(res.data.balance));
      })
      .catch((error) => console.error(error))
      .finally(() => dispatch(setIsLoading(false)));
  }, [
    recipientList,
    campaign,
    campaignValue,
    numberOfLinks,
    dispatch,
    navigate,
  ]);

  return (
    <div className="relative min-h-screen">
      <div ref={topDivRef}></div>
      <Header logo={true} title={campaign.name}></Header>
      <div className="p-4">
        <BackButton onClick={() => navigate.back()} />
        <RecipientsProgress
          selectedRecipients={selectedRecipients}
          setSelectedRecipients={setSelectedRecipients}
          setRecipientList={setRecipientList}
          setPaginationIndex={setPageNumber}
          setSelectedRecipientGroup={setSelectedRecipientGroup}
          recipientList={recipientList}
          recipientGroups={recipientGroups}
          numberOfLinks={numberOfLinks}
          setnumberOfLinks={setnumberOfLinks}
          selectedRecipientGroup={selectedRecipientGroup}
          selectAllRecipients={selectAllRecipients}
          totalPages={totalPages}
          updateValues={updateValues}
          values={campaignValue}
          setCampaignValue={setCampaignValue}
          pageSize={pageSize}
          setPageNumber={setPageNumber}
          className="mx-auto w-[85%] mb-4"
        />
      </div>
      <FooterAddRecipients
        recipientList={recipientList}
        campaign={campaign}
        onclickTopUpWallet={() => setShowTopUpModal(true)}
        onConfim={onConfirm}
        selectedRecipients={selectedRecipients}
      />
      {showTopUpModal && <TopUpModal setShowTopUpModal={setShowTopUpModal} />}
    </div>
  );
};

export default AddRecipients;
