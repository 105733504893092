import CreateAccountPage from "../components/CreateAccountPage/CreateAccountPage";
import { SignUpPage } from "../pages/Auth/Auth";
import EmailVerificationRequired from "../pages/Auth/EmailVerificationRequired";
import VerifyEmail from "../pages/Auth/VerifyEmail";
import NotFound from "../pages/NotFound";
import { SingleCorp } from "../pages/TempForms/Corps/SingleCorp";
import { Ocassions } from "../pages/TempForms/Ocassion/Ocassions";
import { updateSuccessfull } from "../pages/UpdateSuccess";
import WalletTransaction from "../pages/WalletTransaction/WalletTransaction";
import Accounts from "../pages/dashboard/Accounts";
import Billing from "../pages/dashboard/Billing";
import CampaignAddRecipients from "../pages/dashboard/Campaign/AddRecipients";
import CampaignCreate from "../pages/dashboard/CampaignCreate";
import CampaignCreated from "../pages/dashboard/CampaignCreated";
import CampaignDetails from "../pages/dashboard/CampaignDetails";
import Campaigns from "../pages/dashboard/Campaigns";
import Dashboard from "../pages/dashboard/Dashboard";
import Engagement from "../pages/dashboard/Engagment/Engagement";
import TouchpointCreate from "../pages/dashboard/Engagment/TouchpointForm";
import TouchPointResponse from "../pages/dashboard/Engagment/TouchpointResponse";
import { IndividualRecipient } from "../pages/dashboard/IndividualRecipient";
import Recipents from "../pages/dashboard/Recipients";
import CategoryDetail from "../pages/giftSelection/CategoryDetail";
import Checkout from "../pages/giftSelection/Checkout";
import ChooseCategory from "../pages/giftSelection/ChooseCategory";
import GiftSelectionLanding from "../pages/giftSelection/GiftSelectionLanding";
import PersonalizedMessage from "../pages/giftSelection/PersonalizedMessage";
import Thankyou from "../pages/giftSelection/Thankyou";
import ThankyouNotes from "../pages/giftSelection/ThankyouNotes";
import VoucherCategoryDetail from "../pages/giftSelection/VouchersCategoryDetail";
import CategoryDetailHamper from "../pages/giftSelectionHamper/CategoryDetailHamper";
import CheckoutHamper from "../pages/giftSelectionHamper/CheckoutHamper";
import ChooseCategoryHamper from "../pages/giftSelectionHamper/ChooseCategoryHamper";
import ThankyouHamper from "../pages/giftSelectionHamper/ThankyouHamper";
import CampaignEnded from "../pages/giftingCommon/CampaignEnded";
import EventStartingSoon from "../pages/giftingCommon/EventStartingSoon";
import GiftSelected from "../pages/giftingCommon/GiftSelected";

export type pathType =
  | "/gift-selection"
  | "/gift-selection/personalized-message"
  | "/gift-selection/choose-category"
  | "/gift-selection/choose-category/:id"
  | "/gift-selection/checkout"
  | "/gift-selection/payment"
  | "/gift-selection/thank-you"
  | "/gift-selection/thank-you-notes"
  | "/hamper/gift-selection"
  | "/hamper/gift-selection/personalized-message"
  | "/hamper/gift-selection/choose-category"
  | "/hamper/gift-selection/choose-category/:id"
  | "/hamper/gift-selection/checkout"
  | "/hamper/gift-selection/payment"
  | "/hamper/gift-selection/thank-you"
  | "/hamper/gift-selection/thank-you-notes"
  | "/gift-selection/:corpName/choose-category"
  | "/gift-selection/:corpName/choose-category/:id"
  | "/gift-selection/:corpName/checkout"
  | "/gift-selection/:corpName/payment"
  | "/gift-selection/:corpName/thank-you"
  | "/gift-selection/:corpName/thank-you-notes"
  | "/dashboard"
  | "/dashboard/campaigns"
  | "/dashboard/campaigns/:id"
  | "/dashboard/campaigns/:id/add-recipients"
  | "/dashboard/campaigns/create"
  | "/dashboard/campaigns/create/success"
  | "/dashboard/accounts"
  | "/dashboard/accounts/:id"
  | "/dashboard/recipents"
  | "/dashboard/recipients/:id"
  | "/dashboard/engagement"
  | "/dashboard/touchpoints/create"
  | "/dashboard/touchpoints/:id"
  | "/dashboard/touchpointResponses/:id"
  | "/dashboard/billing"
  | "/dashboard/settings"
  | "/gift-selection/event-starting-soon"
  | "/gift-selection/event-ended"
  | "/gift-selection/gift-selected"
  | "/forms"
  | "/admin"
  | "/ocassion"
  | "/ocassion"
  | "/login"
  | "/auth"
  | "/verify-email"
  | "/email-verification-required"
  | "CreateAccountPage"
  | "/updateSuccess"
  | "/singleCorp"
  | "/notFound"
  | "/ocassion"
  | "/wallet-transaction"
  | "/gift-selection/choose-voucher-category/:id"
  | "/gift-selection/voucher-checkout";

export type roleType = "Corp" | "SuperAdmin";
export enum roleTypes {
  Corp = "Corp",
  SuperAdmin = "SuperAdmin",
}
const routes: {
  name: string;
  path: pathType;
  component: () => JSX.Element;
  roles: roleType[];
  auth?: boolean;
}[] = [
  {
    name: "Gift Selection",
    path: "/gift-selection",
    component: GiftSelectionLanding,
    roles: [],
    auth: undefined,
  },
  {
    name: "Personalized Message",
    path: "/gift-selection/personalized-message",
    component: PersonalizedMessage,
    roles: [],
    auth: undefined,
  },
  {
    name: "Choose Category",
    path: "/gift-selection/choose-category",
    component: ChooseCategory,
    roles: [],
    auth: undefined,
  },
  {
    name: "Choose Category",
    path: "/gift-selection/choose-category/:id",
    component: CategoryDetail,
    roles: [],
    auth: undefined,
  },
  {
    name: "Choose Voucher Category",
    path: "/gift-selection/choose-voucher-category/:id",
    component: VoucherCategoryDetail,
    roles: [],
    auth: undefined,
  },
  {
    name: "Checkout",
    path: "/gift-selection/checkout",
    component: Checkout,
    roles: [],
    auth: undefined,
  },
  {
    name: "Thankyou",
    path: "/gift-selection/thank-you",
    component: Thankyou,
    roles: [],
    auth: undefined,
  },
  {
    name: "Thankyou Notes",
    path: "/gift-selection/thank-you-notes",
    component: ThankyouNotes,
    roles: [],
    auth: undefined,
  },
  {
    name: "Choose Category",
    path: "/hamper/gift-selection/choose-category",
    component: ChooseCategoryHamper,
    roles: [],
    auth: undefined,
  },
  {
    name: "Choose Category",
    path: "/hamper/gift-selection/choose-category/:id",
    component: CategoryDetailHamper,
    roles: [],
    auth: undefined,
  },
  {
    name: "Checkout",
    path: "/hamper/gift-selection/checkout",
    component: CheckoutHamper,
    roles: [],
    auth: undefined,
  },
  {
    name: "Thankyou",
    path: "/hamper/gift-selection/thank-you",
    component: ThankyouHamper,
    roles: [],
    auth: undefined,
  },
  {
    name: "Thankyou Notes",
    path: "/hamper/gift-selection/thank-you-notes",
    component: ThankyouNotes,
    roles: [],
    auth: undefined,
  },
  {
    name: "EventStarting",
    path: "/gift-selection/event-starting-soon",
    component: EventStartingSoon,
    roles: [],
    auth: false,
  },
  {
    name: "CampaignEnded",
    path: "/gift-selection/event-ended",
    component: CampaignEnded,
    roles: [],
    auth: false,
  },
  {
    name: "GiftSelected",
    path: "/gift-selection/gift-selected",
    component: GiftSelected,
    roles: [],
    auth: false,
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    component: Dashboard,
    roles: [],
    auth: true,
  },
  {
    name: "Campaigns",
    path: "/dashboard/campaigns",
    component: Campaigns,
    roles: [],
    auth: true,
  },
  {
    name: "Campaign Details",
    path: "/dashboard/campaigns/:id",
    component: CampaignDetails,
    roles: [],
    auth: true,
  },
  {
    name: "Campaign Add Recipients",
    path: "/dashboard/campaigns/:id/add-recipients",
    component: CampaignAddRecipients,
    roles: [],
    auth: true,
  },
  {
    name: "Campaign Details",
    path: "/dashboard/campaigns/create",
    component: CampaignCreate,
    roles: [],
    auth: true,
  },
  {
    name: "Campaign Created",
    path: "/dashboard/campaigns/create/success",
    component: CampaignCreated,
    roles: [],
    auth: true,
  },
  {
    name: "Engagement",
    path: "/dashboard/engagement",
    component: Engagement,
    roles: [],
    auth: true,
  },

  {
    name: "Touchpoint Update",
    path: "/dashboard/touchpoints/:id",
    component: TouchpointCreate,
    roles: [],
    auth: true,
  },
  {
    name: "Touchpoint Response",
    path: "/dashboard/touchpointResponses/:id",
    component: TouchPointResponse,
    roles: [],
    auth: true,
  },
  {
    name: "Touchpoints",
    path: "/dashboard/touchpoints/create",
    component: TouchpointCreate,
    roles: [],
    auth: true,
  },
  {
    name: "Accounts",
    path: "/dashboard/accounts",
    component: Accounts,
    roles: [],
    auth: true,
  },
  {
    name: "Recipents",
    path: "/dashboard/recipents",
    component: Recipents,
    roles: [],
    auth: true,
  },
  {
    name: "Individual Recipient",
    path: "/dashboard/recipients/:id",
    component: IndividualRecipient,
    roles: [],
    auth: true,
  },
  {
    name: "Billing",
    path: "/dashboard/billing",
    component: Billing,
    roles: [],
    auth: true,
  },
  {
    name: "Ocassion",
    path: "/ocassion",
    component: Ocassions,
    roles: [],
    auth: true,
  },
  {
    name: "login",
    path: "/login",
    component: SignUpPage,
    roles: [],
    auth: false,
  },
  {
    name: "SignUp",
    path: "/auth",
    component: SignUpPage,
    roles: [],
    auth: false,
  },
  {
    name: "VerifyEmail",
    path: "/verify-email",
    component: VerifyEmail,
    roles: [],
    auth: undefined,
  },
  {
    name: "EmailVerificationRequired",
    path: "/email-verification-required",
    component: EmailVerificationRequired,
    roles: [],
    auth: false,
  },
  {
    name: "CreateAccountPage",
    path: "CreateAccountPage",
    component: CreateAccountPage,
    roles: [],
    auth: true,
  },
  {
    name: "Update Success",
    path: "/updateSuccess",
    component: updateSuccessfull,
    roles: [],
    auth: true,
  },
  {
    name: "Single Corp",
    path: "/singleCorp",
    component: SingleCorp,
    roles: [],
    auth: true,
  },
  {
    name: "Not Found",
    path: "/notFound",
    component: NotFound,
    roles: [],
    auth: undefined,
  },
  {
    name: "Wallet Transaction",
    path: "/wallet-transaction",
    component: WalletTransaction,
    roles: [],
    auth: true,
  },
];

export default routes;
