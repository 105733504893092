import axios from "axios";
import moment from "moment";

class _Api {
  constructor() {
    console.log(this.baseGiftingUrl);
    console.log(this.baseCorpUrl);
    console.log(this.baseAuthUrl);
    console.log(this.baseAdminUrl);
  }

  local =
    process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "development"
      ? false
      : process.env.NODE_ENV === "development";

  baseGiftingRemoteUrl =
    process.env.REACT_APP_GIFTING_URL ||
    "https://gifting.smilie.io/api/gifting/";
  baseCorpRemoteUrl =
    process.env.REACT_APP_CORP_URL || "https://corp.smilie.io/api/corp/";
  baseAuthRemoteUrl =
    process.env.REACT_APP_AUTH_URL || "https://auth.smilie.io/api/auth/";
  baseAdminRemoteUrl =
    process.env.REACT_APP_ADMIN_URL || "https://admin.smilie.io/api/admin/";

  baseAuthLocalUrl = "http://localhost:4000/api/auth/";
  baseCorpLocalUrl = "http://localhost:4001/api/corp/";
  baseGiftingLocalUrl = "http://localhost:4002/api/gifting/";
  baseAdminLocalUrl = "http://localhost:4003/api/admin/";

  baseGiftingUrl = this.local
    ? this.baseGiftingLocalUrl
    : this.baseGiftingRemoteUrl;
  baseCorpUrl = this.local ? this.baseCorpLocalUrl : this.baseCorpRemoteUrl;
  baseAuthUrl = this.local ? this.baseAuthLocalUrl : this.baseAuthRemoteUrl;
  baseAdminUrl = this.local ? this.baseAdminLocalUrl : this.baseAdminRemoteUrl;

  loginUrl = this.baseAuthUrl + "access/login";
  loginWithTokenUrl = this.baseAuthUrl + "access/login/token";
  verifyEmailUrl = this.baseAuthUrl + "access/verify/email";
  registerUrl = this.baseAuthUrl + "access/";
  googleLoginUrl = this.baseAuthUrl + "access/login/google";
  resetPasswordUrl = this.baseAuthUrl + "access/resetPassword";
  resetPasswordVerifyUrl = this.baseAuthUrl + "access/resetPassword/verify";
  updatePhoneNumberUrl = this.baseAuthUrl + "access/updatePhoneNumber";

  createOccasionUrl = this.baseAdminUrl + "occasion/";
  getAllOccasionUrl = this.baseAdminUrl + "occasion/";
  createCollectionUrl = this.baseAdminUrl + "collection/";
  getAllCollectionUrl = this.baseAdminUrl + "collection/";
  createCharityUrl = this.baseCorpUrl + "charity/";

  createCorpCollectionsUrl = this.baseAdminUrl + "collection/corp/multiple";
  azureUrl = this.baseCorpUrl + "media/";

  createProductUrl = this.baseAdminUrl + "product/";
  getSingleProductUrl = this.baseAdminUrl + "product/";
  getAllProductUrl = this.baseAdminUrl + "product/";
  deleteProductUrl = this.baseAdminUrl + "product/";
  createProductCategoryUrl = this.baseAdminUrl + "product/category";
  getAllProductCategoriesUrl = this.baseAdminUrl + "product/category";
  getProductsByCategoryIdUrl = (categoryId: number) =>
    this.baseAdminUrl + "product/category/" + categoryId + "/products";
  deleteProductCategoryUrl = this.baseAdminUrl + "product/category/";
  createProductSubCategoryUrl = this.baseAdminUrl + "product/subCategory";
  getAllProductSubCategoriesUrl = this.baseAdminUrl + "product/subCategory";
  updateProductSubCategoryUrl = this.baseAdminUrl + "product/subCategory";
  getAllProductMasterUrl = this.baseAdminUrl + "productTag/master";
  createProductOccasiontagsUrl =
    this.baseAdminUrl + "product/occasionTag/multiple";

  getAllOrdersUrl = this.baseGiftingUrl + "/order/";
  getAllConsolidatedOrdersUrl = this.baseGiftingUrl + "consolidated-order/";
  createProductCollectionTagUrl = this.baseAdminUrl + "product/collectionTag";
  sendEmailToRecipientUrl = this.baseGiftingUrl + "recipient/email";
  getActiveTouchpointsUrl = this.baseGiftingUrl + "engagement/";
  saveEngagementResponseUrl = (recipientUrl: string) =>
    this.baseGiftingUrl + `/engagement/${recipientUrl}/response`;

  getAllrecipientGroupsUrl = this.baseGiftingUrl + "recipient/group";
  getAllGroupsByRecipientIdUrl =
    this.baseGiftingUrl + "recipient/recipient-groups";

  updateCampaignUrl = this.baseGiftingUrl + "campaign/";

  getOneCorpUrl = this.baseCorpUrl + "";
  getAllCorpUrl = this.baseCorpUrl + "";
  addCorpBalanceUrl = this.baseCorpUrl + "transaction/wallet";
  getallDashboardCamapaignUrl = this.baseAdminUrl + "dashboard/campaign";
  getallDashboardtopgiftUrl = this.baseAdminUrl + "dashboard/main";
  getallDashboardRecipientUrl = this.baseAdminUrl + "dashboard/recipient";
  createUpdateRecipientUrl = this.baseAdminUrl + "dashboard/recipient";

  getAllReferralsUrl = this.baseCorpUrl + "engagement/referrals";
  getAllTouchpointResponsesUrl = (touchpointId: string) =>
    this.baseCorpUrl + `engagement/touchpoints/${touchpointId}/responses`;
  getAllTouchpointsUrl = this.baseCorpUrl + "engagement/touchpoints";
  deleteRecipientUrl = (id: number) =>
    this.baseAdminUrl + `dashboard/recipient/${id}`;
  createCampaignUrl = this.baseCorpUrl + "campaign/";
  validateRecipientUrl = this.baseCorpUrl + "campaign/recipientField";
  getCampaignByFilterUrl = this.baseCorpUrl + "campaign/filter";
  getAllCampaignUrl = this.baseCorpUrl + "campaign/";
  getOneCampaignUrl = this.baseCorpUrl + "campaign/";
  getAllCharityUrl = this.baseCorpUrl + "charity/";

  getAllAddOnsUrl = this.baseAdminUrl + "addon/";
  createAddOnUrl = this.baseAdminUrl + "addon/";

  testEmailUrl = this.baseGiftingUrl + "campaign/testEmail";

  testWhatsappUrl = this.baseGiftingUrl + "wati/sendTestWhatsapp";

  getCampaignRecipientUrl = this.baseGiftingUrl + "recipient/select-gift/";
  getPreviewGiftUrl = this.baseGiftingUrl + "recipient/preview";
  getRecipientRecommendedProductsUrl =
    this.baseGiftingUrl + "recipient/product/recommended";
  createOrderUrl = this.baseGiftingUrl + "order";
  createOrderMultipleUrl = this.baseGiftingUrl + "order/multiple";

  getAllCustomFieldsUrl = (corpId: number) =>
    this.baseCorpUrl + `${corpId}/recipientCustomFields`;
  getAllRecipientCustomFieldsUrl = this.baseCorpUrl + "recipient/customFields";
  getAllRecipientCustomFieldValuesUrl = (recipientId: number) =>
    this.baseCorpUrl + "recipient/customFields/" + recipientId;

  getOrderBrUrlIdUrl = this.baseGiftingUrl + "order/urlId/";

  getAllOrderStatusUrl = this.baseGiftingUrl + "order/status";

  updateConsolidatedOrderDeliveryDate =
    this.baseGiftingUrl + "consolidated-order/";
  getStatusAccToOrderTypeUrl = this.baseGiftingUrl + "order/type";

  filterJournalEntriesUrl = this.baseAdminUrl + "journalEntry/filter";
  updateJournalEntryUrl = this.baseAdminUrl + "journalEntry/";

  // Stripe
  getCreateCheckoutUrl = this.baseCorpUrl + "stripe/createCheckoutSession";
  getGetSubscriptionUrl = this.baseCorpUrl + "stripe/getSubscription";

  // Voucher
  activateVoucherbyId = this.baseGiftingUrl + "voucher/activate";

  createOccasion = async (
    e: React.FormEvent<HTMLFormElement>,
    ocassionData: object
  ) => {
    e.preventDefault();

    const data: CreateOccasion = {
      // @ts-ignore
      name: ocassionData.name,
      // @ts-ignore
      type: ocassionData.type,
      // @ts-ignore
      sentiment: ocassionData.sentiment,
      // @ts-ignore
      picture: ocassionData.picture,
    };
    try {
      const res = await axios.post(this.createOccasionUrl, data);
      return res.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  getAllTouchpoints = async (limit: number) => {
    const res = await axios.get(this.getAllTouchpointsUrl, {
      params: { limit },
    });
    return res.data;
  };

  getActiveTouchpoints = async (recipientId: string) => {
    const res = await axios.get(this.getActiveTouchpointsUrl + recipientId);
    return res.data;
  };

  createCorp = async (
    e: React.FormEvent<HTMLFormElement>,
    corpData: any,
    addresses: any,
    customPlan: any
  ) => {
    e.preventDefault();
    let data = {
      roleId: 1,
      occ_id: "?",
      uat_apikey: "123456",
      live_apikey: "123456",
      logo: corpData.logo,
      organisationName: corpData.organisationName,
      phoneNumber: corpData.phoneNumber,
      employeeRange: corpData.employeeRange,
      firstName: corpData.firstName,
      middleName: corpData.middleName,
      lastName: corpData.lastName,
      template: corpData.template,
      referralId: corpData.referralId,
      govId: corpData.govId,
      govIdType: corpData.govIdType,
      taxId: corpData.taxId,
      taxNumber: corpData.taxNumber,
      balance: Number(corpData.balance),
      oldBalance: Number(corpData.oldBalance),
      creditConversationRatio: Number(corpData.creditConversationRatio),
      addresses: addresses,
      customPlan: {
        defaultPlanId: Number(customPlan.defaultPlanId),
        name: customPlan.name,
        description: customPlan.description,
        isActive: customPlan.isActive,
        isAnnual: customPlan.isAnnual,
        listPrice: Number(customPlan.listPrice),
        discount: Number(customPlan.discount),
        discountAb: customPlan.discountAb,
        actualPrice: Number(customPlan.actualPrice),
        renualPrice: Number(customPlan.renualPrice),
        deliveryCharge: Number(customPlan.deliveryCharge),
      },
    };
    try {
      const res = await axios.post(this.baseCorpUrl, data);
      return res.data;
    } catch (error) {
      return error;
    }
  };

  updateCorp = async (
    id: number,
    corpData: any,
    addresses: any,
    customPlan: any
  ) => {
    const data = {
      roleId: 1,
      occ_id: "?",
      uat_apikey: "123456",
      live_apikey: "123456",
      logo: corpData.logo,
      organisationName: corpData.organisationName,
      phoneNumber: corpData.phoneNumber,
      employeeRange: corpData.employeeRange,
      firstName: corpData.firstName,
      middleName: corpData.middleName,
      lastName: corpData.lastName,
      template: corpData.template,
      referralId: corpData.referralId,
      govId: corpData.govId,
      govIdType: corpData.govIdType,
      taxId: corpData.taxId,
      taxNumber: corpData.taxNumber,
      balance: Number(corpData.balance),
      oldBalance: Number(corpData.oldBalance),
      creditConversationRatio: Number(corpData.creditConversationRatio),
      addresses: addresses,
      customPlan: {
        defaultPlanId: Number(customPlan.defaultPlanId),
        name: customPlan.name,
        description: customPlan.description,
        isActive: customPlan.isActive,
        isAnnual: customPlan.isAnnual,
        listPrice: Number(customPlan.listPrice),
        discount: Number(customPlan.discount),
        discountAb: customPlan.discountAb,
        actualPrice: Number(customPlan.actualPrice),
        renualPrice: Number(customPlan.renualPrice),
        deliveryCharge: Number(customPlan.deliveryCharge),
      },
    };
    try {
      const res = await axios.patch(this.baseCorpUrl + `${id}`, data);
      return res.data;
    } catch (error) {
      return error;
    }
  };

  updateSubCategory = async (
    data: any,
    id: number,
    isAddSubCatOption: boolean
  ) => {
    try {
      if (isAddSubCatOption) {
        const res = await axios.post(this.createProductSubCategoryUrl, data);
        return res.data;
      } else {
        const res = await axios.patch(
          this.updateProductSubCategoryUrl + `/${id}`,
          data
        );

        return res.data;
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  updateConsolidatedOrderDate = async (data: any) => {
    const res = await axios.patch(
      this.updateConsolidatedOrderDeliveryDate + `${data.orderId}`,
      data
    );
    return res.data;
  };

  updateOcassion = async (id: number, ocassionData: object) => {
    const data: CreateOccasion = {
      // @ts-ignore
      name: ocassionData.name,
      // @ts-ignore
      type: ocassionData.type,
      // @ts-ignore
      sentiment: ocassionData.sentiment,
      // @ts-ignore
      picture: ocassionData.picture,
    };
    try {
      const res = await axios.patch(this.createOccasionUrl + `${id}`, data);
      return res.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  uploadingImgToAzure = async (formData: FormData) => {
    try {
      const res = await axios.post(this.azureUrl, formData);
      return res.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  deletingImgFromAzure = async (imgUrl: any) => {
    try {
      const data = { url: imgUrl };
      const res = await axios.delete(this.azureUrl, { data });
      return res.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  createCollection = async (
    e: React.FormEvent<HTMLFormElement>,
    collectionData: object,
    isRecommended: boolean
  ) => {
    e.preventDefault();

    const data: CreateCollection = {
      // @ts-ignore
      name: collectionData.name,
      // @ts-ignore
      type: collectionData.type,
      // @ts-ignore
      description: collectionData.description,
      // @ts-ignore
      sentiment: collectionData.sentiment,
      // @ts-ignore
      isRecommended: isRecommended,
      // @ts-ignore
      picture: collectionData.picture,
    };

    try {
      const res = await axios.post(this.createCollectionUrl, data);
      return res.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  updateCollection = async (
    id: number,
    collectionData: object,
    isRecommended: boolean
  ) => {
    const data: CreateCollection = {
      // @ts-ignore
      name: collectionData.name,
      // @ts-ignore
      type: collectionData.type,
      // @ts-ignore
      description: collectionData.description,
      // @ts-ignore
      sentiment: collectionData.sentiment,
      // @ts-ignore
      isRecommended: isRecommended,
      // @ts-ignore
      picture: collectionData.picture,
    };
    try {
      const res = await axios.patch(this.createCollectionUrl + `${id}`, data);
      return res.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  createProductCollectionTag = async (data: {
    collectionId: number;
    productIds: number[];
  }) => {
    try {
      for await (const productId of data.productIds) {
        const reqData = {
          collectionId: data.collectionId,
          productId,
        };
        const res = await axios.post(
          this.createProductCollectionTagUrl,
          reqData
        );
      }
      return;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  createCharity = async (e: any, charityData: object) => {
    e.preventDefault();
    try {
      const res = await axios.post(this.createCharityUrl, charityData);
      return res.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
  updateCharity = async (id: number, charityData: any) => {
    try {
      const data: any = {
        name: charityData.name,
        description: charityData.description,
        location: charityData.location,
        accountInfo: charityData.accountInfo,
        pic1: charityData.pic1,
        pic2: charityData.pic2,
        pic3: charityData.pic3,
        pic4: charityData.pic4,
        balance: Number(charityData.balance),
        lastBalance: Number(charityData.lastBalance),
      };
      const res = await axios.patch(this.createCharityUrl + `${id}`, data);
      return res.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
  createAddOn = async (addOnData: Partial<AddOn>) => {
    const res = await axios.post<DataResponse<AddOn>>(
      this.createAddOnUrl,
      addOnData
    );
    return res.data.data;
  };
  updateAddOn = async (id: number, addOnData: Partial<AddOn>) => {
    const data = {
      ...addOnData,
      listPrice: Number(addOnData.listPrice),
      costPrice: Number(addOnData.costPrice),
      maxQuantity: Number(addOnData.maxQuantity),
    };
    const res = await axios.patch<DataResponse<AddOn>>(
      this.createAddOnUrl + `${id}`,
      data
    );
    return res.data.data;
  };
  deleteAddOn = async (id: number) => {
    const res = await axios.delete<DataResponse<AddOn>>(
      this.createAddOnUrl + `${id}`
    );
    return res.data;
  };

  updateProductCategory = async (id: any, categoryData: any) => {
    const data = {
      name: categoryData.name,
      image: categoryData.image,
    };
    const res = await axios.patch(
      this.createProductCategoryUrl + `/${id}`,
      data
    );
    return res.data;
  };

  getAllOrders = async (
    limit: number,
    offset: number,
    selectedStatusFilter?: number,
    sort?: string,
    sortOrder?: string
  ) => {
    const res = await axios.get(this.getAllOrdersUrl, {
      params: { limit, offset, status: selectedStatusFilter, sort, sortOrder },
    });
    return res.data;
  };

  getAllReferrals = async (
    limit: number,
    pageNumber: number,
    sort?: string,
    sortOrder?: string,
    searchInput?: string
  ) => {
    const res = await axios.get(this.getAllReferralsUrl, {
      params: { limit, sort, sortOrder, searchInput, pageNumber },
    });
    return res.data;
  };

  getAllTouchPointResponses = async (
    touchpointId: string,
    limit: number,
    pageNumber: number,
    sort?: string,
    sortOrder?: string,
    searchInput?: string
  ) => {
    const res = await axios.get(
      this.getAllTouchpointResponsesUrl(touchpointId),
      {
        params: { limit, pageNumber, sort, sortOrder, searchInput },
      }
    );
    return res.data;
  };

  getAllConsolidatedOrders = async (
    limit: number,
    offset: number,
    selectedStatusFilter?: number,
    sort?: string,
    sortOrder?: string
  ) => {
    const res = await axios.get(this.getAllConsolidatedOrdersUrl, {
      params: { limit, offset, status: selectedStatusFilter, sort, sortOrder },
    });
    return res.data;
  };

  getAllProducts = async () => {
    const res = await axios.get(this.getAllProductUrl);
    return res.data as GetAllProduct;
  };
  getAllAddOns = async () => {
    const res = await axios.get(this.getAllAddOnsUrl);
    return res.data as GetAllAddOns;
  };

  getAllOccasion = async () => {
    const res = await axios.get(this.getAllOccasionUrl);
    return res.data as GetAllOccasionT;
  };

  getSingleProduct = async (id: any) => {
    const res = await axios.get(this.getSingleProductUrl + id);
    return res.data;
  };
  getAllOccasionWithCollection = async (_corpId?: number) => {
    const corpId = _corpId ? `&corpId=${_corpId}` : "";

    const res = await axios.get(
      this.getAllOccasionUrl + "?withCollection=true" + corpId
    );
    return res.data as GetAllOccasionWithCollectionT;
  };

  getAllCollection = async () => {
    const res = await axios.get(this.getAllCollectionUrl);
    return res.data as GetAllCollectionResT;
  };

  getAllProductCategories = async () => {
    const res = await axios.get(this.getAllProductCategoriesUrl);
    return res.data.data as DashboardResProductCategory[];
  };

  getProductsByCategoryId = async (categoryId: number) => {
    const res = await axios.get(this.getProductsByCategoryIdUrl(categoryId));
    return res.data.data as GetAllProductData[];
  };

  getAllProductSubCategories = async () => {
    const res = await axios.get(this.getAllProductSubCategoriesUrl);
    return res.data.data as ProductSubCategory[];
  };

  getAllrecipientGroups = async () => {
    const res = await axios.get(this.getAllrecipientGroupsUrl);
    return res.data as GetAllRecipientGroup;
  };
  getRecipientGroupsByRecipientId = async (id: number) => {
    const res = await axios.get(`${this.getAllGroupsByRecipientIdUrl}/${id}`);
    return res.data as GetAllRecipientGroup;
  };
  getAllCharity = async () => {
    const res = await axios.get(this.getAllCharityUrl);
    return res.data as GetAllCharities;
  };
  getAllOrderStatus = async () => {
    const res = await axios.get(this.getAllOrderStatusUrl);
    return res.data;
  };
  getStatusAccToOrderType = async () => {
    const res = await axios.get(this.getStatusAccToOrderTypeUrl);
    return res.data;
  };
  getAllCorps = async () => {
    const res = await axios.get(this.baseCorpUrl);
    return res.data;
  };
  getAllProductMaster = async () => {
    const res = await axios.get(this.getAllProductMasterUrl);
    return res.data as GetProductTags;
  };

  getOneCorp = async (id: number) => {
    const res = await axios.get(this.getOneCorpUrl + id);
    return res.data as GetOneCorp;
  };

  getAllCorp = async () => {
    const res = await axios.get(this.getAllCorpUrl);
    return res.data as GetAllCorp;
  };

  getCampaignRecipient = async (url_id: string) => {
    const res = await axios.get(this.getCampaignRecipientUrl + url_id);
    return res.data as GetCampaignRecipient;
  };

  getPreviewGift = async (
    productIds: number[],
    message: string,
    recipientMessageTitle: string,
    messageFromName: string,
    footerGreeting: string,
    recipientName: string
  ) => {
    const params = {
      productIds,
      message,
      recipientMessageTitle,
      messageFromName,
      footerGreeting,
      recipientName,
    };
    const res = await axios.get(this.getPreviewGiftUrl, { params });
    return res.data as GetCampaignRecipient;
  };

  getRecipientRecommendedProducts = async () => {
    const res = await axios.get(this.getRecipientRecommendedProductsUrl);
    return res.data.data as GetRecipientRecommendedProduct[];
  };
  getOrderFromUrlId = async (urlId: string) => {
    const res = await axios.get(this.getOrderBrUrlIdUrl + urlId);
    return res.data as GetOrderResponse;
  };

  getAllCampaign = async () => {
    const res = await axios.get(`${this.getAllCampaignUrl}`);
    return res.data as GetAllCampaign;
  };
  getallDashBoardCampaign = async (filter?: {
    fromDate: string;
    toDate: string;
  }) => {
    const filterStr = filter
      ? `?fromDate=${filter.fromDate}&toDate=${filter.toDate}`
      : "";
    const res = await axios.get(
      `${this.getallDashboardCamapaignUrl}${filterStr}`
    );
    return res.data as CampaignList;
  };

  getallDashboardData = async (filter: {
    fromDate?: string;
    toDate?: string;
    campaignId?: number;
  }) => {
    const url = this.getallDashboardtopgiftUrl;
    const res = await axios.get(
      `${url}?fromDate=${filter.fromDate}&toDate=${filter.toDate}&campaignId=${filter.campaignId}`
    );
    return res.data as DashboardRes;
  };

  getallDashboardRecipient = async () => {
    const url = this.getallDashboardRecipientUrl;
    const res = await axios.get(`${url}`);
    return res.data as DashboardRecipient;
  };

  createUpdateRecipient = async (data: CreateRecipient) => {
    const url = this.createUpdateRecipientUrl;
    const res = await axios.post(`${url}`, data);
    return res.data;
  };

  deleteRecipient = async (id: number) => {
    const url = this.deleteRecipientUrl(id);
    const res = await axios.delete(`${url}`);
    return res.data;
  };

  getOneCampaign = async (id: number) => {
    const res = await axios.get(this.getOneCampaignUrl + id);
    return res.data as GetOneCampaign;
  };

  getCorpPaynowImgSource = async (id: number) => {
    const res = await axios.get(`${this.baseCorpUrl + id}/paynowQRCode`);
    return res.data;
  };

  getCampaignByFilter = async (filter: GetCampaignByFilterReqData) => {
    const res = await axios.get(
      `${this.getCampaignByFilterUrl}?name=${filter.name}&corpId=${filter.corpId}`
    );
    return res.data as {
      success: boolean;
      data?: any;
    };
  };

  createProductCategory = async (data: {
    name: string;
    subCats: string[];
    image: string;
  }) => {
    try {
      const catRes = await axios.post(this.createProductCategoryUrl, {
        name: data.name,
        image: data.image,
      });

      const id = catRes.data.data.id;
      for await (const subCategory of data.subCats) {
        const reqData: CreateProductSubCategory = {
          name: subCategory,
          productCategoryId: id,
        };
        await axios.post(this.createProductSubCategoryUrl, reqData);
      }
      return;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  deleteProductCategory = async (id: number) => {
    try {
      const res = await axios.delete(this.deleteProductCategoryUrl + id);
      return res.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  createCampaign = async (data: CreateCampaign) => {
    const res = await axios.post(this.createCampaignUrl, data);

    return res.data as CreateCampaignResponse;
  };

  checkValidRecipientField = async (data: validateRecipientField) => {
    const res = await axios.post(this.validateRecipientUrl, data);

    return res.data as ValidateRecipientResponse;
  };
  createProduct = async (data: TCreateProduct) => {
    try {
      const res = await axios.post(this.createProductUrl, data);

      return res.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  deleteProduct = async (id: number) => {
    try {
      const res = await axios.delete(this.deleteProductUrl + id, {
        data: {
          isDeleted: true,
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  updateProduct = async (id: number, productData: any) => {
    //the any will be changed later
    try {
      const data: any = {
        brand: productData.brand,
        name: productData.name,
        description: productData.description,
        image_1: productData.image_1,
        image_2: productData.image_2,
        image_3: productData.image_3,
        image_4: productData.image_4,
        image_5: productData.image_5,
        image_6: productData.image_6,
        gender: productData.gender,
        listPrice: productData.listPrice,
        retailPrice: productData.retailPrice,
        costPrice: productData.costPrice,
        deliveryFee: productData.deliveryFee,
        sellPlatform: productData.sellPlatform,
        sellPlatformUrl: productData.sellPlatformUrl,
        productType: productData.productType,
        hasSelfPickupOption: productData.hasSelfPickupOption,
        hasDeliveryOption: productData.hasDeliveryOption,
        colourName: productData.colourName,
        productTagMasterIds: productData.productTagMasterIds,
        selfPickupAddress: productData.selfPickupAddress,
        productCategoryId: Number(productData.productCategoryId),
        productSubCategoryId: Number(productData.productSubCategoryId),
        deliveryDurationLatest:
          Number(productData.deliveryDurationLatest) * 24 * 60 * 60,
        deliveryDurationEarliest:
          Number(productData.deliveryDurationEarliest) * 24 * 60 * 60,
      };
      const res = await axios.patch(this.createProductUrl + `${id}`, data);
      return res.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
  createOrder = async (data: CreateOrder) => {
    const res = await axios.post(this.createOrderUrl, data);
    return res.data as CreateOrderResponse;
  };

  activateVoucher = async (data: ActivateVoucherOrder) => {
    const res = await axios.post(this.activateVoucherbyId, data);
    return res.status;
  };

  createMultipleOrder = async (data: CreateOrderMultiple) => {
    const res = await axios.post(this.createOrderMultipleUrl, data);
    return res.data as CreateMultipleOrderRes;
  };

  createProductOccasiontags = async (
    data: CreateProductOccasionTagMultiple
  ) => {
    const res = await axios.post(this.createProductOccasiontagsUrl, data);
    return res.data;
  };

  createCorpCollections = async (data: CreateCorpCollectionMultiple) => {
    const res = await axios.post(this.createCorpCollectionsUrl, data);
    return res.data;
  };

  sendEmailToRecipient = async (
    isSingle: boolean,
    senderName: string,
    recipientData: CampaignListRecipient
  ) => {
    const res = await axios.post(this.sendEmailToRecipientUrl, {
      isSingle,
      senderName,
      recipientData,
    });
    return res;
  };

  addBalance = async (corpId: number, amount: number) => {
    try {
      const res = await axios.post(this.addCorpBalanceUrl, {
        corpId,
        amount: amount,
        type: "cr",
      });

      return res.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  login = async (email: string, password: string) => {
    const res = await axios.post(this.loginUrl, {
      email,
      password,
    });

    return res.data as LoginRes;
  };

  loginWithToken = async (token: string) => {
    const res = await axios.post(
      this.loginWithTokenUrl,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res.data as LoginRes;
  };

  verifyEmail = async (verificationCode: string) => {
    const res = await axios.post(this.verifyEmailUrl, {
      verificationCode,
    });

    return res.data as LoginRes;
  };

  register = async (data: RegisterData) => {
    const res = await axios.post(this.registerUrl, data);

    return res.data as LoginRes;
  };

  googleLogin = async (googleToken: string) => {
    const res = await axios.post(this.googleLoginUrl, {
      googleToken,
    });

    return res.data as LoginRes;
  };

  resetPassword = async (email: string) => {
    const res = await axios.post(this.resetPasswordUrl, {
      email,
    });

    return res.data;
  };

  resetPasswordVerify = async (data: {
    resetPasswordCode: string;
    password: string;
  }) => {
    const res = await axios.post(this.resetPasswordVerifyUrl, data);

    return res.data;
  };

  updatePhoneNumber = async (data: { phoneNumber: string }) => {
    const res = await axios.post(this.updatePhoneNumberUrl, data);
    return res.data;
  };

  updateCampaign = async (
    id: number,
    data: {
      sendMessageViaWhatsapp: boolean;
    }
  ) => {
    const res = await axios.patch(this.updateCampaignUrl + id, data);

    return res.data;
  };

  testEmail = async (data: {
    receiverName: string;
    senderEmail: string;
    senderName: string;
    companyName: string;
    link: string;
    subject: string;
    to: string;
  }) => {
    const res = await axios.post(`${this.testEmailUrl}`, data);
    return res.data;
  };

  testWhatsapp = async (data: {
    phoneNumber: string;
    receiverName: string;
    senderName: string;
    senderPhoneNumber: string;
    senderEmail: string;
    recId: string;
  }) => {
    const res = await axios.post(`${this.testWhatsappUrl}`, data);
    return res.data;
  };
  convertToCSV(customFields: TCustomField[]) {
    interface DataItem {
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber: string;
      department: string;
      role: string;
      [key: string]: string | undefined; // Dynamic keys for custom fields
    }

    const sampleData: DataItem[] = [
      {
        firstName: "Samuel",
        lastName: "Tan",
        email: "samuel_tan@gmail.com",
        phoneNumber: "12345678",
        department: "IT",
        role: "Engineer",
        ...customFields.reduce((acc, field) => {
          acc[field.field] = moment(new Date()).format("DD MMM YYYY"); // Use custom field names directly
          return acc;
        }, {} as { [key: string]: string }),
      },
    ];

    const headerToPropertyMap: { [key: string]: keyof DataItem } = {
      "First Name": "firstName",
      "Last Name": "lastName",
      Email: "email",
      "Phone Number": "phoneNumber",
      Department: "department",
      Role: "role",
      ...customFields.reduce((acc, field) => {
        acc[field.field] = field.field as keyof DataItem; // Use custom field names directly
        return acc;
      }, {} as { [key: string]: keyof DataItem }),
    };

    const columnDelimiter = ",";
    const lineDelimiter = "\n";

    const headers = Object.keys(headerToPropertyMap);

    let csv = headers.join(columnDelimiter) + lineDelimiter;
    sampleData.forEach((item) => {
      headers.forEach((header, index) => {
        if (index > 0) csv += columnDelimiter;
        const propertyKey = headerToPropertyMap[header];
        csv += item[propertyKey] ? item[propertyKey] : "";
      });
      csv += lineDelimiter;
    });

    return csv;
  }

  downloadTemplate = (customFields: TCustomField[]) => {
    const data = this.convertToCSV(customFields);
    const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", "smilie_bulk_recipients_template.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  getStripeCheckoutUrl = async () => {
    const res = await axios.get(this.getCreateCheckoutUrl);
    return res.data;
  };

  getSubscription = async () => {
    const res = await axios.get(this.getGetSubscriptionUrl);
    return res.data;
  };

  saveEngagementResponse = async (
    recipientUrl: string,
    data: {
      touchpoints: { id: number; response: string }[];
      referrals: { name: string; contact: string }[];
    }
  ) => {
    const res = await axios.post(
      this.saveEngagementResponseUrl(recipientUrl),
      data
    );
    return res.data;
  };

  addRecipients = async (id: number, data: AddRecipients) => {
    const res = await axios.post(
      this.createCampaignUrl + `${id}/add-recipients`,
      data
    );

    return res.data as CreateCampaignResponse;
  };

  filterJournalEntries = (token: string, input: JournalEntryFilterInput) =>
    axios
      .post<DataResponse<JournalEntry[]>>(this.filterJournalEntriesUrl, input, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data.data);

  updateJournalEntry = (token: string, id: number, actualCost: number) =>
    axios
      .post<DataResponse<JournalEntry>>(
        this.updateJournalEntryUrl + id,
        { actualCost },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data.data);

  getAllCustomFields = async (corpId: number) => {
    const res = await axios.get<DataResponse<TCustomField[]>>(
      this.getAllCustomFieldsUrl(corpId)
    );

    return res.data;
  };

  getAllRecipientCustomFields = () =>
    axios
      .get<DataResponse<TRecipientCustomField[]>>(
        this.getAllRecipientCustomFieldsUrl
      )
      .then((res) => res.data.data);
  getAllRecipientCustomFieldsValues = (recipientId: number) =>
    axios
      .get<DataResponse<TRecipientCustomFieldValue[]>>(
        this.getAllRecipientCustomFieldValuesUrl(recipientId)
      )
      .then((res) => res.data.data);
}

const Api = new _Api();

export default Api;
