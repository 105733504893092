import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Accordin from "../../components/common/Accordin";
import BackButtonGifting from "../../components/giftSelection/BackButton";
import Header from "../../components/giftSelection/Header";
import CartDrawerHamper from "../../components/giftSelectionHamper/CartDrawerHamper";
import FooterHamper from "../../components/giftSelectionHamper/FooterHamper";
import images from "../../constants/images";
import { addToVoucherCart } from "../../redux/cartSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Voucher, VoucherCategoryDetailProp } from "../../types/vouchers";
import { getUsedCredits } from "../../utils/cartUtils";
import CustomNavigate from "../../utils/navigate";
import {
  CREDITS_PER_DOLLAR,
  MIN_PRODUCT_CREDITS,
} from "../giftSelectionHamper/ChooseCategoryHamper";
import { HamperDenominationQuantity } from "../giftSelectionHamper/HamperDenominationQuantity";

const VoucherCategoryDetail = () => {
  const selectGiftState = useAppSelector((state) => state.selectGiftState);
  const recipientId =
    useSearchParams()[0].get("recid") ||
    selectGiftState.campaignRecipientData?.result.url_id;
  const [drawerVisible, setDrawerVisible] = useState(false);
  const navigate = new CustomNavigate(useNavigate(), { recipientId });

  const dispatch = useAppDispatch();

  const [selectedImage, setSelectedImage] = useState(0);

  const state: VoucherCategoryDetailProp = useLocation().state;

  const [voucher, setVoucher] = useState<Voucher | null>(null);
  const [isHamper, setIsHamper] = useState(false);
  // re-routing to the Choose Category component if the props are null
  useEffect(() => {
    if (state == null) {
      navigate.to("/gift-selection");
      return;
    }
    const { isHamper, voucher } = state;
    if (voucher == null || isHamper == null) {
      navigate.to("/gift-selection");
    }
    setVoucher(voucher);
    setIsHamper(isHamper);
  }, [state]);

  const cartState = useAppSelector((state) => state.cartState);
  const totalCredits = cartState.totalCredits;
  const cart = cartState.cart;
  const voucherCart = cartState.voucherCart;
  const usedCredits = getUsedCredits(cart, voucherCart);
  const creditsAvailable = totalCredits - usedCredits;
  const productImages = [];
  if (voucher?.image_1) productImages.push(voucher?.image_1);
  if (voucher?.image_2) productImages.push(voucher?.image_2);
  if (voucher?.image_3) productImages.push(voucher?.image_3);
  if (voucher?.image_4) productImages.push(voucher?.image_4);
  if (voucher?.image_5) productImages.push(voucher?.image_5);
  if (voucher?.image_6) productImages.push(voucher?.image_6);

  const nextImage = useCallback(() => {
    setSelectedImage((previousSelect) => {
      if (previousSelect + 1 < productImages.length) {
        return previousSelect + 1;
      }
      return previousSelect;
    });
  }, [productImages.length]);

  const previousImage = useCallback(() => {
    setSelectedImage((previousSelect) => {
      if (previousSelect - 1 > -1) {
        return previousSelect - 1;
      }
      return previousSelect;
    });
  }, []);

  const corpState = useAppSelector((state) => state.corpSliceState);
  const logo =
    corpState.data && corpState.data.logo && corpState.data.logo !== ""
      ? corpState.data.logo
      : undefined;

  const isDeactivated = useMemo(
    () => selectGiftState.campaignRecipientData?.result?.isForTesting ?? false,
    [selectGiftState.campaignRecipientData?.result?.isForTesting]
  );

  const getExpiryDate = (days: number | undefined) => {
    if (days == undefined) {
      return "There is no expiry date";
    }
    return `${days} days from claim date`;
  };
  return (
    <div className="">
      <Header navigate={navigate} logo={logo} isPreview={isDeactivated} />
      <div className="m-6 mt-[100px]">
        <BackButtonGifting navigate={navigate} />
      </div>
      <div className="grid md:grid-cols-2 max-md:grid-cols-1 md:gap-16 max-md:gap-4 md:mt-5 max-md:mt-5 md:mx-36 max-md:mx-5">
        <div>
          <div className="relative border rounded-2xl">
            <button
              className="absolute top-[45%] left-2 p-[12px] rounded-full bg-white"
              onClick={() => previousImage()}
            >
              <img
                src={
                  selectedImage !== 0 ? images.leftArrow : images.leftArrowGrey
                }
                className="w-[16px] aspect-square "
                alt=""
              />
            </button>
            <button
              className="absolute top-[45%] right-2 p-[12px] rounded-full bg-white"
              onClick={() => nextImage()}
            >
              <img
                src={
                  selectedImage + 1 === productImages.length
                    ? images.leftArrowGrey
                    : images.leftArrow
                }
                className="w-[16px] aspect-square rotate-180"
                alt=""
              />
            </button>
            <div className="flex justify-center">
              <img
                src={productImages[selectedImage]}
                className="w-full aspect-square object-contain rounded-2xl"
                alt=""
              />
            </div>
          </div>
          <div className="flex gap-6 overflow-x-auto mt-5">
            {productImages.map((item, index) => {
              return (
                <img
                  src={item}
                  key={index}
                  className={`w-[93px] aspect-square mr-1 rounded-lg cursor-pointer object-contain ${
                    selectedImage === index && "border border-orange"
                  }`}
                  onClick={() => setSelectedImage(index)}
                  alt=""
                />
              );
            })}
          </div>
        </div>

        <div>
          <div className="font-leagueSpartan-500 text-[32px] text-neutrals-900 ">
            {voucher?.name}
          </div>
          <div className="mt-4  rounded-xl">
            {!isHamper && (
              <button
                className="mt-2 p-3 px-8 rounded-lg bg-orange font-leagueSpartan-500 text-[16px] text-white tracking-[1.6px]"
                onClick={() => {
                  navigate.to("/gift-selection/checkout", undefined, {
                    voucher,
                    product: null,
                  });
                }}
              >
                Checkout
              </button>
            )}
            {isHamper && (
              <HamperDenominationQuantity
                productCredit={voucher?.credits || MIN_PRODUCT_CREDITS}
                denominations={voucher?.denominations || []}
                addToCartHandler={(quantity: number, denomination: number) => {
                  if (voucher == undefined) {
                    return;
                  }
                  dispatch(
                    addToVoucherCart({
                      id: voucher?.id,
                      denomination: denomination,
                      credits: denomination / CREDITS_PER_DOLLAR,
                      quantity: quantity,
                      image_1: voucher.image_1,
                      name: voucher.name,
                      currency: voucher.currency,
                    })
                  );
                }}
                creditsAvailable={creditsAvailable}
              />
            )}
          </div>
          <div className="mt-4 mb-20"> 
            {voucher?.description && (
              <Accordin
                data={[
                  {
                    title: "Description",
                    description: voucher.description,
                    id: 1,
                    checked: false,
                  },
                ]}
              />
            )}
            {voucher?.termsContent && (
              <Accordin
                data={[
                  {
                    title: "Terms and Conditions",
                    description: voucher.termsContent,
                    id: 2,
                    checked: false,
                  },
                ]}
              />
            )}
            <Accordin
              data={[
                {
                  title: "Validity",
                  description: getExpiryDate(voucher?.expiryDays),
                  id: 3,
                  checked: true,
                },
              ]}
            />
          </div>
        </div>
      </div>
      {isHamper && (
        <FooterHamper
          onViewBasketClick={() => setDrawerVisible(true)}
          productCart={cart}
          voucherCart={voucherCart}
          showProgress={true}
          credits={{
            total: totalCredits,
            used: getUsedCredits(cart, cartState.voucherCart),
          }}
        />
      )}

      {isHamper && drawerVisible && (
        <CartDrawerHamper setDrawerVisible={setDrawerVisible} />
      )}
    </div>
  );
};

export default VoucherCategoryDetail;
