import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import BackButton from "../../components/common/BackButton";
import CampaignDetailsTabs from "../../components/dashboard/CampaignDetailsTabs";
import Main from "../../components/dashboard/Main";
import CustomNavigate from "../../utils/navigate";

const CampaignDetails = () => {
  const navigate = new CustomNavigate(useNavigate());

  const campaign = useLocation().state as {
    name: string;
  };

  return (
    <>
      <Main
        title={campaign.name}
        logo={true}
        headerChildren={
          <BackButton
            onClick={() => {
              navigate.back();
            }}
          />
        }
      >
        <div>
          <CampaignDetailsTabs />
        </div>
      </Main>
    </>
  );
};

export default CampaignDetails;
