import { Form, Formik } from "formik";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Checkbox } from "../../components/Checkbox/Checkbox";
import { Textarea } from "../../components/Textarea/Textarea";
import Header from "../../components/giftSelection/Header";
import Api from "../../constants/api";
import images from "../../constants/images";
import { useAppSelector } from "../../redux/hooks";
import CustomNavigate from "../../utils/navigate";
import ReferralSection from "../giftingCommon/ReferralSection";

type TEnagementFormValues = {
  touchpoints: { id: number; response: string }[];
  referrals: { name: string; contact: string }[];
};
const Thankyou = () => {
  const selectGiftState = useAppSelector((state) => state.selectGiftState);
  const recipientId =
    useSearchParams()[0].get("recid") ||
    selectGiftState.campaignRecipientData?.result.url_id;
  const navigate = new CustomNavigate(useNavigate(), { recipientId });
  const fromName =
    selectGiftState.campaignRecipientData?.result.campaign.messageFromName ||
    "";

  const [touchpoints, setTouchpoints] = useState<Touchpoint[]>([]);
  const [orders, setOrders] = useState<GetOrderResponseData>();
  const isTouchpointEnabled =
    selectGiftState.campaignRecipientData?.result.campaign.isTouchpointEnabled;
  const isReferralEnabled =
    selectGiftState.campaignRecipientData?.result.campaign.isReferralEnabled;
  const deliveryDuration = useMemo(() => {
    if (!orders) {
      return {
        earliest: -1,
        latest: -1,
      };
    }
    return orders?.orders.reduce(
      (acc, order) => {
        const { deliveryDurationEarliest = -1, deliveryDurationLatest = -1 } =
          order.product;
        if (deliveryDurationEarliest > acc.earliest) {
          acc.earliest = deliveryDurationEarliest;
        }
        if (deliveryDurationLatest > acc.latest) {
          acc.latest = deliveryDurationLatest;
        }

        return acc;
      },
      {
        earliest: -1,
        latest: -1,
      }
    );
  }, [orders]);
  useEffect(() => {
    if (!isReferralEnabled && !isTouchpointEnabled) {
      navigate.to("/gift-selection/thank-you-notes", undefined, {
        skipped: false,
      });
    }
  }, []);

  const getAllTouchpoints = useCallback(async (recipientId: string) => {
    try {
      const response = await Api.getActiveTouchpoints(recipientId);
      if (response.data) {
        setTouchpoints(response.data);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  }, []);

  const getOrderDetails = useCallback(async (recipientId: string) => {
    try {
      const response = await Api.getOrderFromUrlId(recipientId);
      if (response.data) {
        setOrders(response.data);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  }, []);

  useEffect(() => {
    if (!recipientId) return;
    getAllTouchpoints(recipientId);
    getOrderDetails(recipientId);
  }, [getAllTouchpoints, getOrderDetails, recipientId]);

  const initialValues: TEnagementFormValues = {
    touchpoints: [],
    referrals: [{ name: "", contact: "" }],
  };

  return (
    <div className="font-leagueSpartan">
      <Header />
      <div className="flex relative justify-center text-center mt-[74px]">
        <img
          className="absolute top-[10vh] max-md:hidden -z-10"
          src={images.thankyou}
          alt=""
        />
        <img
          className="absolute top-[10vh] md:hidden max-md:top-[40vh] -z-10"
          src={images.thankyouSm}
          alt=""
        />
        <div className="w-10/12 max-w-screen-md">
          <div className="my-6 font-leagueSpartan-500 text-orange text-[56px] tracking-[1.45px]">
            Before you go...
          </div>
          <div className="my-6 font-leagueSpartan-300">
            Confirmation will be sent to your email.
          </div>
          {deliveryDuration.earliest > 0 && deliveryDuration.latest && (
            <div className="my-6 font-leagueSpartan-300">
              <div>Estimated Delivery</div>
              <div className="font-leagueSpartan font-black">
                {`${moment(orders?.createdAt)
                  .add(deliveryDuration?.earliest || 0, "seconds")
                  .format("DD MMMM")}
              to ${moment(orders?.createdAt)
                .add(deliveryDuration?.latest || 0, "seconds")
                .format("DD MMMM YYYY")}`}
              </div>
            </div>
          )}
          <div className="py-5 px-10 mb-10 bg-orange-100 text-center rounded-xl">
            <div className="font-leagueSpartan-400 text-[24px] text-neutrals-900">
              Engage with {fromName || "sender"}
            </div>
            <Formik
              initialValues={initialValues}
              validate={() => {}}
              onSubmit={(values) => {
                if (recipientId)
                  Api.saveEngagementResponse(recipientId, values).finally(() =>
                    navigate.to("/gift-selection/thank-you-notes", undefined, {
                      skipped: false,
                    })
                  );
              }}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  {isTouchpointEnabled && (
                    <div className="text-left">
                      {touchpoints.length > 0 &&
                        touchpoints.map(
                          (touchpoint: Touchpoint, index: number) =>
                            touchpoint.allowTextResponse ? (
                              <Textarea
                                key={touchpoint.id}
                                header={touchpoint.title}
                                name={`touchpoints.${index}.response`}
                                placeholder={touchpoint.description}
                                onChange={(e) => {
                                  const { value: response } = e.target;
                                  if (response) {
                                    setFieldValue(`touchpoints`, [
                                      ...values.touchpoints.filter(
                                        ({ id }) => id !== touchpoint.id
                                      ),
                                      {
                                        id: touchpoint.id,
                                        response,
                                      },
                                    ]);
                                  } else
                                    setFieldValue(`touchpoints`, [
                                      ...values.touchpoints.filter(
                                        ({ id }) => id !== touchpoint.id
                                      ),
                                    ]);
                                }}
                              />
                            ) : (
                              <Checkbox
                                className="bg-white p-2 my-5 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                key={touchpoint.id}
                                text={touchpoint.title}
                                description={touchpoint.description}
                                onClick={() => {
                                  if (
                                    values.touchpoints.some(
                                      ({ id }) => id === touchpoint.id
                                    )
                                  ) {
                                    setFieldValue(
                                      `touchpoints`,
                                      values.touchpoints.filter(
                                        ({ id }) => id !== touchpoint.id
                                      )
                                    );
                                  } else {
                                    setFieldValue(`touchpoints`, [
                                      ...values.touchpoints,
                                      {
                                        response: "✅",
                                        id: touchpoint.id,
                                      },
                                    ]);
                                  }
                                }}
                                checked={values.touchpoints.some(
                                  ({ id }) => id === touchpoint.id
                                )}
                                readOnly
                              />
                            )
                        )}
                    </div>
                  )}
                  {isReferralEnabled && (
                    <div>
                      <div className="text-lg text-left">Refer A Friend:</div>
                      {values.referrals.map((_, index) => (
                        <ReferralSection
                          key={index}
                          referralNameInput={{
                            value: values.referrals[index].name,
                            onChange: (e) =>
                              setFieldValue(
                                `referrals.${index}.name`,
                                e.target.value
                              ),
                          }}
                          referralContactInput={{
                            value: values.referrals[index].contact,
                            onChange: (e) =>
                              setFieldValue(
                                `referrals.${index}.contact`,
                                e.target.value
                              ),
                          }}
                          handleDeleteReferral={() => {
                            setFieldValue(
                              `referrals`,
                              values.referrals.filter(
                                (_, referralIndex) => referralIndex !== index
                              )
                            );
                          }}
                        />
                      ))}
                      <div>
                        <button
                          type="button"
                          className="m-2 py-2 px-4 bg-orange text-white text-sm border border-orange-300 rounded rounded-lg"
                          onClick={() =>
                            setFieldValue("referrals", [
                              ...values.referrals,
                              { name: "", contact: "" },
                            ])
                          }
                        >
                          + Add Friend
                        </button>
                      </div>
                    </div>
                  )}

                  <div className="my-4 flex justify-center">
                    <button
                      className="mx-2 p-2 px-4 rounded-lg text-sm font-leagueSpartan-400 border border-orange bg-white text-orange"
                      onClick={() => {
                        navigate.to(
                          "/gift-selection/thank-you-notes",
                          undefined,
                          {
                            skipped: false, // TODO: when skipped, this should be true
                          }
                        );
                      }}
                    >
                      Skip for now
                    </button>
                    <button
                      type="submit"
                      className="mx-2 p-2 px-4 rounded-lg text-sm font-leagueSpartan-400 bg-orange text-white"
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
