import moment from "moment";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import images from "../../constants/images";
import "./accordin.css";

type TCampaign = {
  data: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    department: string;
    role: string;
    campaigns: DashboardRecipientDataCampaign[];
    checked?: boolean;
  }[];
  createRecipientModel: boolean;
  setCreateRecipientModel: React.Dispatch<React.SetStateAction<boolean>>;
  setCreateRecipientModelEditId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  deleteRecipient: (id: number) => void;
};
const AccordinCampaigns = (props: TCampaign) => {
  const {
    data,
    setCreateRecipientModel,
    setCreateRecipientModelEditId,
    deleteRecipient,
  } = props;

  const [list, setList] = useState(data);
  const setItem = useCallback((key: number, checked: boolean) => {
    setList((l) => {
      l[key].checked = checked;
      return [...l];
    });
  }, []);

  useEffect(() => {
    setList(data);

    return () => {};
  }, [data]);

  return (
    <table className="w-full bg-white font-leagueSpartan">
      <thead className="w-full p-2 bg-neutrals-400 font-leagueSpartan-600">
        <tr>
          <th className="p-4 text-left">First Name </th>
          <th className="p-4">Last Name </th>
          <th className="p-4">Email </th>
          <th className="p-4">Phone Number </th>
          <th className="p-4">Department</th>
          <th className="p-4">Role</th>
          <th colSpan={3} className="p-4">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        {/* <!--  Panel 1  --> */}
        {list.map((a, index) => {
          return (
            <Fragment key={a.id}>
              <tr className="border-t text-center">
                <td className="p-4 text-left">{a.firstName}</td>
                <td className="p-4">{a.lastName}</td>
                <td className="p-4">{a.email}</td>
                <td className="p-4">{a.phoneNumber}</td>
                <td className="p-4">{a.department}</td>
                <td className="p-4">{a.role}</td>
                <td colSpan={2}>
                  <div className="flex items-center justify-around">
                    <Link
                      className="block"
                      to={`../dashboard/recipients/${a.id}`}
                      state={a}
                    >
                      <img
                        src={images.edit}
                        alt=""
                        className="cursor-pointer size-8"
                      />
                    </Link>
                    <img
                      src={images.dustbinred}
                      alt=""
                      className="cursor-pointer"
                      onClick={() => {
                        deleteRecipient(a.id);
                      }}
                    />
                  </div>
                </td>
                <td>
                  <label
                    htmlFor={`panel-${a.id}`}
                    className="flex justify-between font-leagueSpartan-400 text-[18px] cursor-pointer"
                  >
                    <img
                      src={images.downAngularArrow}
                      className={`${a.checked ? "rotate-180" : ""}`}
                      alt=""
                    />
                  </label>
                  <input
                    type="checkbox"
                    name="panel"
                    id={`panel-${a.id}`}
                    className="hidden"
                    checked={a.checked}
                    onChange={(e) => setItem(index, e.target.checked)}
                  />
                </td>
              </tr>
              <tr className="p-0">
                <td colSpan={99}>
                  <input
                    type="checkbox"
                    name="panel"
                    id={`panel-${a.id}`}
                    className="hidden"
                    checked={a.checked}
                    onChange={(e) => setItem(index, e.target.checked)}
                  />
                  <div className="accordion__content overflow-hidden bg-grey-lighter">
                    <table className="w-full border">
                      <thead className="bg-neutrals-300 ">
                        <tr>
                          <th className="font-leagueSpartan-600 text-[14px] text-neutrals-700">
                            CAMPAIGN
                          </th>
                          <th className="font-leagueSpartan-600 text-[14px] text-neutrals-700">
                            STATUS
                          </th>
                          <th className="font-leagueSpartan-600 text-[14px] text-neutrals-700">
                            DATE SENT
                          </th>
                          <th className="font-leagueSpartan-600 text-[14px] text-neutrals-700">
                            DATE CLAIMED
                          </th>
                          <th className="font-leagueSpartan-600 text-[14px] text-neutrals-700">
                            ACCOUNT SPENT
                          </th>
                          <th className="font-leagueSpartan-600 text-[14px] text-neutrals-700">
                            GIFT SELECTED
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {a.campaigns.map((val, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-neutrals-900 font-leagueSpartan-300 text-[16px] max-w-[200px]">
                                {val.camapignName || "-"}{" "}
                              </td>
                              <td className="text-center">
                                <div className="flex text-sm font-leagueSpartan-400">
                                  <div
                                    className={`px-2 mt-1 rounded font-leagueSpartan-500 text-[14px] whitespace-nowrap ${
                                      val.status === "Redeemed" &&
                                      "bg-green-100 text-green-800"
                                    }
                                ${
                                  val.status === "Opened" &&
                                  "bg-orange-100 text-orange"
                                }
                                ${
                                  val.status === "Not opened" &&
                                  "bg-red-100 text-red-800"
                                }`}
                                  >
                                    {val.status}
                                  </div>
                                </div>
                              </td>
                              <td className="text-neutrals-900 font-leagueSpartan-300 text-[16px] ">
                                {val.dateSent
                                  ? moment(val.dateSent).format(
                                      "DD MMM YYYY, hh:mm"
                                    )
                                  : "-"}
                              </td>
                              <td className="text-neutrals-900 font-leagueSpartan-300 text-[16px] ">
                                {val.dateClaimed
                                  ? moment(val.dateClaimed).format(
                                      "DD MMM YYYY, hh:mm"
                                    )
                                  : "-"}
                              </td>
                              <td className="text-neutrals-900 font-leagueSpartan-300 text-[16px]  ">
                                ${val.totalSpent}
                              </td>
                              <td className="text-neutrals-900 font-leagueSpartan-300 text-[16px] max-w-[200px]">
                                {val.giftSelected || "-"}{" "}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export default AccordinCampaigns;
